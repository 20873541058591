import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const waldburger: FC = () => {
  const titleImage = "feelgood2020_tic_060.jpg"
  const image1 = "feelgood2020_tic_003.jpg"
  const image2 = "feelgood2020_tic_022.jpg"
  const image3 = "feelgood2020_tic_066.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Waldburger/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Waldburger 2020
          <h5 className="single-project-text">
            Piccolo intervento atto a dare più luce all’interno del salotto di
            una vecchia casa nel nucleo di Busada a Mergoscia e di implementare
            un modo più funzionale di riscaldare gli ambienti. Con il
            proprietario abbiamo optato per creare una nuova apertura
            integrandola nella facciata in pietra ed apponendo un serramento con
            telaio legno-legno. Inoltre è stata recuperata una vecchia stufa di
            oltre 120 anni ristrutturandola e certificandola Feu-Suisse, in modo
            da essere conformi con le norme vigenti. Il camino aperto che
            contraddistingue il salotto è stato risanato in modo da ridurre il
            rischio di incendio ma grazie alla “nuova” stufa che, è molto più
            efficiente, non viene quasi più utilizzato.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Waldburger/" + image1}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Waldburger/" + image2}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Waldburger/" + image3}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default waldburger
